import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { reactive } from 'vue'

import { getActivePinia } from 'pinia'
import { Pinia } from 'pinia-class-component'

import * as Sentry from '@sentry/browser'

import { ErrorType, RequestError, handleRequestError } from '#utils/request/requestError'
import { waitingForData } from '#utils/store/dataWait'

import { AbortControllerContainer, DataWait, DataWaits, Nullable, RequestErrors } from '#types'

export class BaseStore extends Pinia {
  protected resetAllowed = true

  protected abortControllers: { [x: string]: any } = {}

  protected dataWaits: DataWaits = reactive({})

  protected requestErrors: RequestErrors = reactive({})

  public constructor() {
    super()

    // https://ponyfoo.com/articles/binding-methods-to-class-instance-objects
    this.makeRequest = this.makeRequest.bind(this)
    this.waitingForData = this.waitingForData.bind(this)
    this.updateDataWait = this.updateDataWait.bind(this)
    this.getRequestError = this.getRequestError.bind(this)
    this.resetRequestError = this.resetRequestError.bind(this)
    this.handleRequestError = this.handleRequestError.bind(this)
    this.removeAbortController = this.removeAbortController.bind(this)
    this.updateAbortControllers = this.updateAbortControllers.bind(this)
    this.cancelRequestsByRequestSource = this.cancelRequestsByRequestSource.bind(this)
  }

  /**
   * This is a wrapper-like helper function to make requests from Store with Axios.
   *
   * In addition to making request via Axios, this function handles user ID check, maintains abort controllers
   * and updates data wait state.
   *
   * Previous in-flight requests with same requestSource within the store will be aborted, so you should make sure
   * each store action has unique requestSource
   *
   * Improvement ideas:
   * - Some store actions make lots of data processing so endDataWait is needed. We should figure out more elegant
   *   way to handle this. Now separate markRequestCompleted() call is needed.
   *
   * @param axiosRequestConfig  Standard AxiosRequestConfig
   * @param requestSource       Request source identifier.
   * @param memberId            Member ID associated with the store action
   * @param storeMemberId       Member ID read from the global user-store (currently active member)
   * @param endDataWait         Update data wait status after we get response
   */
  public async makeRequest(
    axiosRequestConfig: AxiosRequestConfig,
    requestSource: string,
    memberId: string | null = null,
    storeMemberId: string | null = null,
    endDataWait: boolean = true,
  ): Promise<AxiosResponse | null> {
    if (memberId && storeMemberId && memberId !== storeMemberId) {
      const error = new Error(`Member IDs do not match. memberId:, ${memberId}, storeMemberId:, ${storeMemberId}`)
      Sentry.captureException(error)
      console.error(error)
      return null
    }

    axiosRequestConfig.apiEnv = sessionStorage.OuraCloudEnv

    const droppedPermissions = (sessionStorage.OuraOverrides || '').split(',')

    const sensitiveDataVisible = sessionStorage.OuraSensitiveDataVisible === 'true'

    if (!sensitiveDataVisible && !droppedPermissions.includes('allowSensitiveDataAccess')) {
      axiosRequestConfig.dropPermissions = ['allowSensitiveDataAccess']
    }

    this.updateDataWait({ source: requestSource, wait: true })

    this.cancelRequestsByRequestSource(requestSource)

    const controller = new AbortController()
    // We generate random string as requestId - https://stackoverflow.com/a/19964557
    const abortControllerContainer = {
      requestSource: requestSource,
      controller: controller,
      requestId: (Math.random().toString(36) + '00000000000000000').slice(2, 16),
    }
    this.updateAbortControllers(abortControllerContainer)
    axiosRequestConfig['signal'] = controller.signal

    const response = await getActivePinia()!
      .$axios(axiosRequestConfig)
      .catch((axiosError: AxiosError) => {
        this.removeAbortController(abortControllerContainer)
        if (endDataWait) {
          this.updateDataWait({ source: requestSource, wait: false })
        }
        throw axiosError
      })

    if (endDataWait) {
      this.updateDataWait({ source: requestSource, wait: false })
    }

    if (!controller.signal.aborted) {
      // If request was successful we can remove its AbortController
      this.removeAbortController(abortControllerContainer)
      return response
    }
    // If request was cancelled: remove abort controller and throw exception
    this.removeAbortController(abortControllerContainer)
    throw response
  }

  public getRequestError(requestSource: string): Nullable<RequestError> {
    return this.requestErrors[requestSource]
  }

  public handleRequestError(
    axiosError: AxiosError,
    requestSource: string,
    ignoreErrorCodes: string[] = ['ERR_CANCELED'],
  ): RequestError {
    const requestError = handleRequestError(axiosError)
    if (!axiosError?.code || !ignoreErrorCodes.includes(axiosError.code)) {
      this.requestErrors[requestSource] = requestError

      if (requestError.type === ErrorType.UNEXPECTED_CLIENT_ERROR || requestError.type === ErrorType.UNEXPECTED_ERROR) {
        /**
         * If you see this line as error source it means that we have new type of request error which
         * handleRequestError() doesn't know how to handle yet. This is normal. Just reproduce it, add support for it
         * with unit tests and it's all good. We don't want to blindly just let all unknown errors go though,
         * but handle each unique case separately with distinct error type. "Unknown error" -message will be shown
         * to user in these cases.
         */
        Sentry.captureException(requestError)
      }
    }
    return requestError
  }

  public resetRequestError(requestSource: string) {
    this.requestErrors[requestSource] = null
  }

  public waitingForData(requestSources: string[] = []) {
    return waitingForData(this.dataWaits, requestSources)
  }

  public updateDataWait(wait: DataWait): void {
    this.dataWaits[wait.source] = wait.wait
  }

  public updateAbortControllers(abortControllerContainer: AbortControllerContainer) {
    const source = abortControllerContainer['requestSource']

    if (!this.abortControllers[source]) {
      this.abortControllers[source] = []
    }
    this.abortControllers[source].push(abortControllerContainer)
  }

  public removeAbortController(abortControllerContainer: AbortControllerContainer) {
    const requestSource = abortControllerContainer['requestSource']
    if (!this.abortControllers[requestSource]) {
      return
    }
    for (let i = 0; i < this.abortControllers[requestSource].length; i++) {
      if (this.abortControllers[requestSource][i].requestId === abortControllerContainer.requestId) {
        this.abortControllers[requestSource].splice(i, 1)
      }
    }
  }

  /**
   * Cancels all non-aborted requests related to requestSource, and cleans up abortControllers list.
   */
  public cancelRequestsByRequestSource(requestSource: string) {
    if (this.abortControllers[requestSource]?.length > 0) {
      for (let i = 0; i < this.abortControllers[requestSource].length; i++) {
        const container = this.abortControllers[requestSource][i]
        if (!container.controller.signal.aborted) {
          // We get here for example if we have request which takes a long time to complete (so it's not yet aborted), and
          // application's refresh logic wants to get the same data again. Here we see that there is previous in-flight
          // request, so we cancel it.
          console.debug('Started new request. Cancelled the previous one. requestSource:', requestSource)
          container.controller.abort('Started new request. Cancelled the previous one')
        }
        // Remove AbortController from list, so we don't check it again.
        this.abortControllers[requestSource].splice(i, 1)
      }
    }
  }
}
