<template>
  <v-container style="padding: 8px !important">
    <div v-if="zdTicketNotFound && userFound">
      <AskConsent v-model:zd-ticket-status="zdTicketNotFound" v-model:agent-reason="agentReason" :uuid="uuid" />
    </div>
    <div v-if="!zdTicketNotFound">
      <RingSelector v-if="user" :member="user" />

      <div class="px-4">
        <RingWarranty v-if="user" :ring="ring" />

        <v-banner
          v-if="user?.hipaaStatus"
          rounded="lg"
          :bg-color="'warning'"
          density="compact"
          class="my-2 hipaa-banner"
          text="HIPAA customer"
        />
      </div>

      <v-col align-self="start">
        <v-row class="w-100">
          <v-col class="py-0">
            <v-tabs v-if="uuid && user" v-model="activeTabId" class="mb-3">
              <v-tab key="diagnostics">Diagnostics</v-tab>
              <v-tab key="subscriptions">Subscriptions</v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-window v-if="uuid && user" v-model="activeTabId">
          <v-window-item key="diagnostics" aria-labelledby="diagnostics">
            <DiagnosticsResult v-if="uuid && user" :user="user" :ring="ring" :link="darwinUrl" :sidecar="true" />
          </v-window-item>

          <v-window-item key="subscriptions" aria-labelledby="subscriptions">
            <OuraSubscription v-if="uuid && user" :user="user" :link="darwinUrl" :sidecar="true" />
            <PaymentHistory v-if="user" :user="user" :sidecar="true" class="mt-4" />
            <PaymentMethods :user="user" :sidecar="true" class="mt-4" />
          </v-window-item>
        </v-window>
      </v-col>
    </div>

    <div v-if="!user && !dataWait && uuid && !userFound" class="text-center pa-4">
      No Oura user found with uuid {{ uuid }}
    </div>

    <div
      v-if="!user && !dataWait && email && !userFound && auth.rights.includes('allowPersonalDataAccess')"
      class="text-center pa-4"
    >
      No Oura user found with email {{ email }}
    </div>

    <div
      v-if="!uuid && !dataWait && email && !auth.rights.includes('allowPersonalDataAccess')"
      class="text-center pa-4"
    >
      <br />
      <h3>This user may exist in Darwin!</h3>
      <br />
      This user has no UUID and you do not have email search rights
      <br />
      Please request access at
      <a href="oura.service-now.com/sp">oura.service-now.com</a>
    </div>

    <SidecarSettings v-if="$zafClient" />

    <v-divider v-if="adminDebugMode" class="mx-2" />

    <DebugTools v-if="adminDebugMode" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { ZendeskTicketChecker } from '#mixins/zendeskTicketChecker'

  import { SearchType } from '#utils/user/search'

  import { AppStore, PrefsStore, RingsStore, UserStore } from '#stores'

  import { Ring } from '#types'

  @Component
  class SidecarView extends mixins(ZendeskTicketChecker) {
    public appStore = new AppStore()
    public userStore = new UserStore()
    public ringsStore = new RingsStore()
    public prefsStore = new PrefsStore()

    public uuid = ''
    public email = ''
    public activeTabId: number = 0
    public zdTicketNotFound = false
    public userFound = true
    public agentReason: string = ''
    public ticketId: number = 0

    public ring: any | null = null

    public darwinUrl = import.meta.env.VITE_DARWIN_URL

    public get auth() {
      return this.appStore.user
    }

    public get user() {
      return this.userStore.user
    }

    public get activeRing(): Ring | null {
      return this.ringsStore.activeRing
    }

    public get dataWait() {
      return this.userStore.dataWait
    }

    public get groups() {
      return this.appStore.groups || []
    }

    public get adminDebugMode() {
      return this.prefsStore.adminDebugMode && this.groups?.includes('groupInternalToolingTeam')
    }

    @Watch('user', { immediate: true })
    protected onUserChanged(_val: any, _oldVal: any) {
      if (this.user) {
        if (this.user.rings.length > 0) {
          this.ring = this.user.rings[0]
        }
      }
    }

    @Watch('activeRing')
    protected onRingChange(ring: Ring, _oldVal: any) {
      if (this.user) {
        this.ring = ring
      }
    }

    @Watch('$route.query.uuid')
    protected onUuidRouteChanged(val: string, _oldval: string) {
      const uuid = decodeURIComponent(val || '')?.toLowerCase()
      if (uuid !== this.uuid) {
        this.uuid = uuid

        this.loadUser()
      }
    }

    @Watch('$route.query.email')
    protected onEmailChanged(val: string, _oldVal: string) {
      const email = decodeURIComponent(val || '')?.toLowerCase()
      if (email !== this.email) {
        this.email = email

        this.loadUser()
      }
    }

    public async mounted() {
      if (this.$zafClient) {
        this.initZendeskWatcher()

        const data = await this.$zafClient.get([
          'currentUser',
          'ticket.id',
          'ticket.requester',
          'ticket.assignee.group.name',
          `ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_PRODUCT_ISSUE_FIELD_ID}`,
          `ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_UUID_TICKET_FIELD_ID}`,
          `ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID}`,
        ])
        const requesterData = data['ticket.requester']

        const currentUser = data['currentUser']
        this.ticketId = data['ticket.id']

        const diagnosticField =
          data[`ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID}`]

        // Show or hide QA Diagnostics field if user tag 'qa' is present
        try {
          if (currentUser?.tags && currentUser?.tags.includes('qa') && diagnosticField) {
            this.$zafClient.invoke(
              `ticketFields:custom_field_${import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID}.disable`,
            )
          } else {
            this.$zafClient.invoke(
              `ticketFields:custom_field_${import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID}.hide`,
            )
          }
        } catch (_error) {
          console.log('qa field not enabled')
        }

        // productIssue will be used for most future diagnostic updates
        // TODO: uncomment line below for future siecar updates
        // let productIssue = data[`ticket.customField:custom_field_${this.productIssueId}`]

        // Sends current group to the Zendesk group function
        this.zendeskGroupsChanged(data['ticket.assignee.group.name'])

        this.uuid =
          data[`ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_UUID_TICKET_FIELD_ID}`]?.length == 36
            ? data[`ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_UUID_TICKET_FIELD_ID}`]
            : requesterData?.externalId?.length == 36
              ? requesterData?.externalId
              : null
        this.email = requesterData?.email
      } else {
        this.uuid = decodeURIComponent((this.$route.query.uuid as string) || '')?.toLowerCase()
        this.email = decodeURIComponent((this.$route.query.email as string) || '')?.toLowerCase()
      }

      if (this.auth && (this.uuid || this.email)) {
        await this.loadUser()
      }
    }

    public async checkForZendeskTicket() {
      this.zdTicketNotFound = false
      const user = await this.userStore.getUserAccessLevels(this.uuid)

      if (!user) {
        this.userFound = false
        return
      }

      // Check if the ZD ticket check feature is enabled or not
      const response = await this.getUsersTicketStatus(this.uuid)
      if ('zdTicketNotFound' in response) {
        this.zdTicketNotFound = response['zdTicketNotFound'] ? true : false
      } else if ('reason' in response) {
        this.agentReason = response['reason'] ? response['reason'] : ''
      }
      if (this.zdTicketNotFound) {
        return false
      } else if (this.agentReason) {
        return true
      }
    }

    protected async loadUser() {
      // TODO:  This doesn't currently support emails. So only works for cases when uuid is available
      this.darwinUrl = import.meta.env.VITE_DARWIN_URL

      const hasOpenZdTicket = await this.checkForZendeskTicket()

      // TODO: Remove this uuid check when this supports emails
      if (this.uuid && !hasOpenZdTicket) {
        this.darwinUrl += `users/${this.uuid}`
        return
      }

      if (this.uuid) {
        this.userStore.getUser({
          user: this.uuid,
          agentReason: `Sidecar view ${this.agentReason ? ', Reason: ' + this.agentReason : ''}, ticketID: ${this.ticketId}`,
          hideRingNames: [],
        })
        this.darwinUrl = import.meta.env.VITE_DARWIN_URL + `users/${this.uuid}`
      } else if (this.email) {
        this.userStore
          .search({
            search: this.email,
            searchType: SearchType.SEARCH_TYPE_EMAIL_EXACT,
          })
          .then((response: any) => {
            if (response?.contents?.length) {
              this.uuid = response.contents[0].uuid
              this.darwinUrl = import.meta.env.VITE_DARWIN_URL + `users/${this.uuid}`
              this.userStore.getUser({
                user: this.uuid,
                agentReason: `Sidecar view ${this.agentReason ? ', Reason: ' + this.agentReason : ''}, ticketID: ${this.ticketId}`,
                hideRingNames: [],
              })
            } else {
              this.userFound = false
            }
          })
      }
    }

    // Watcher function for all frontend changes in Zendesk
    public initZendeskWatcher() {
      this.$zafClient.on('*.changed', (e: { propertyName: any; newValue: any }) => {
        let propertyName = e.propertyName
        let newValue = e.newValue
        if (propertyName == 'ticket.assignee.group.name') {
          this.zendeskGroupsChanged(newValue)
        }
      })
    }
    // This function will be called when groups is changed by the agent
    private zendeskGroupsChanged(groupName: string = '') {
      if (groupName == 'Memberships') {
        this.activeTabId = 1
      }
    }
  }

  export default toNative(SidecarView)
</script>
<style lang="scss" scoped>
  :deep(
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
      > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
      .v-slide-group__prev
  ) {
    display: none;
  }

  :deep(.hipaa-banner) {
    font-size: medium;
    word-spacing: -1px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: white !important;
  }
</style>
