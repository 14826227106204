import { AxiosError } from 'axios'

import {
  ApiErrorResponse,
  ApiGWErrorResponse,
  FormValidationErrorResponse,
  FormValidationProblem,
  Nullable,
  isApiErrorResponse,
  isApiGWErrorResponse,
  isFormValidationError,
} from '#types'

export enum ErrorType {
  CLIENT_ERROR = 'ClientError',

  FORM_VALIDATION_ERROR = 'FormValidationError',

  /**
   * Request was cancelled
   */
  REQUEST_CANCELLED = 'RequestCancelled',

  /**
   * Unexpected 5xx error. Probably a temporary backend / proxy issue etc.
   */
  UNEXPECTED_SERVER_ERROR = 'UnexpectedServerError',

  /**
   * 4xx error which has error response in invalid format. The error response
   * should be fixed on the API side to follow the standard error response format.
   */
  UNEXPECTED_CLIENT_ERROR = 'UnexpectedClientError',

  /**
   * Unexpected error which doesn't match any other error criteria
   */
  UNEXPECTED_ERROR = 'UnexpectedError',

  /**
   * 401 response with only message response. example: {"message":"Unauthorized"}
   */
  UNAUTHORIZED = 'Unauthorized',

  PAYLOAD_TOO_LARGE = 'PayloadTooLarge',

  AXIOS_ECONNABORTED = 'ClientSideTimeout',
}

export interface RequestError {
  /**
   * Message to be shown to end user
   */
  userMessage: Nullable<string>

  /**
   * Verbose message
   *
   * Contains full message we retrieved from API, or for example in case of Axios error this contains
   * Axios's error message
   */
  verboseMessage: Nullable<string>

  type: ErrorType

  /**
   * Raw HTTP details
   */
  details: {
    /**
     * Response status code
     *
     * @example 400
     */
    statusCode: Nullable<number>

    /**
     * Response data
     */
    responseData: any

    /**
     * Request URL
     */
    url: Nullable<string>
    // Should we add request body here to make troubleshooting easier?
  }
  formValidationProblems: FormValidationProblem[]
}

/**
 * Handle request error
 *
 * Improvement ideas:
 * - Handle more error cases (request cancelled, timed out, network error etc.)
 */
export function handleRequestError(
  error: AxiosError<any | ApiErrorResponse | FormValidationErrorResponse | ApiGWErrorResponse>,
): RequestError {
  const statusCode = error?.response?.status ?? null
  const details = {
    statusCode: statusCode,
    responseData: error?.response?.data ?? null,
    url: error?.response?.request?.responseURL ?? null,
  }

  if (error?.code === AxiosError.ERR_CANCELED) {
    return {
      userMessage: 'Request was cancelled',
      verboseMessage: null,
      type: ErrorType.REQUEST_CANCELLED,
      details: {
        statusCode: null,
        responseData: null,
        url: error?.config?.url ?? null,
      },
      formValidationProblems: [],
    }
  }

  if (error?.code === AxiosError.ECONNABORTED) {
    return {
      userMessage: 'Request was cancelled because it took too long to get a response from the server.',
      verboseMessage: error?.message ?? null,
      type: ErrorType.AXIOS_ECONNABORTED,
      details: {
        statusCode: null,
        responseData: error?.response?.data ?? null,
        url: error?.config?.url ?? null,
      },
      formValidationProblems: [],
    }
  }

  if (statusCode && statusCode >= 400 && statusCode <= 499) {
    if (isApiErrorResponse(error?.response?.data)) {
      return {
        userMessage: error?.response?.data?.message ?? null,
        verboseMessage: null,
        type: ErrorType.CLIENT_ERROR,
        details: details,
        formValidationProblems: [],
      }
    } else if (isFormValidationError(error?.response?.data)) {
      return {
        userMessage: 'Form validation error',
        verboseMessage: null,
        type: ErrorType.FORM_VALIDATION_ERROR,
        details: details,
        formValidationProblems: error.response.data.problems,
      }
    } else if (isApiGWErrorResponse(error?.response?.data) && statusCode == 401) {
      return {
        userMessage: 'Unauthorized',
        verboseMessage: null,
        type: ErrorType.UNAUTHORIZED,
        details: details,
        formValidationProblems: [],
      }
    } else if (isApiGWErrorResponse(error?.response?.data) && statusCode == 413) {
      return {
        userMessage: 'Request entity too large',
        verboseMessage: null,
        type: ErrorType.PAYLOAD_TOO_LARGE,
        details: details,
        formValidationProblems: [],
      }
    } else {
      return {
        userMessage: 'Unknown error',
        verboseMessage: null,
        type: ErrorType.UNEXPECTED_CLIENT_ERROR,
        details: details,
        formValidationProblems: [],
      }
    }
  }

  if (statusCode && statusCode >= 500 && statusCode <= 599) {
    /*
     * Server error (500, 503, 504) are currently handled by Axios interceptor
     * and global notification is raised from those. We still should probably return this error
     * so we can show in the UI where the error actually happened.
     */
    return {
      userMessage: error?.response?.data?.message ?? 'Unknown server error',
      verboseMessage: null,
      type: ErrorType.UNEXPECTED_SERVER_ERROR,
      details: details,
      formValidationProblems: [],
    }
  }
  // Unexpected error which is not client or server error
  return {
    userMessage: 'Unknown error',
    verboseMessage: null,
    type: ErrorType.UNEXPECTED_ERROR,
    details: details,
    formValidationProblems: [],
  }
}
