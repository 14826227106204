import * as Plotly from 'plotly.js-strict-dist'

import { RequestError } from '#utils/request/requestError'

import { DiagnosticErrorLevel, ReturnEligibilityResponse } from '#types'

export interface BaseStoreState {
  abortControllers: { [key: string]: AbortControllerContainer[] }
  dataWaits: DataWaits
  resetAllowed?: boolean
  requestErrors: RequestErrors
}

export type UserRights =
  | 'allowDataDeletionAccess'
  | 'allowDataDownloadAccess'
  | 'allowPersonalDataAccess'
  | 'allowSensitiveDataAccess'
  | 'allowPartialSearchAccess'

export interface Account {
  email: string
  state: string
  userUid: string
  expiresAt: string
}

export interface Consent {
  consentContentUid?: string
  content: string
}

/*
 * API App models
 */

export interface APIAppListItem {
  uid: string
  clientId: string
  createdAt: string
  name: string
  userCount: number
  status: APIAppStatus
}

export interface APIApp {
  uid: string
  createdAt: string
  isDeleted: boolean
  deletedAt: string | null
  clientId: string
  name: string
  managers: string[]
  homepageUrl: string
  authorizationGrantAllowed: boolean
  implicitGrantAllowed: boolean
  redirectUris: string
  status: APIAppStatus
  description: string
  email: string
  privacyUrl: string
  serviceUrl: string
  userCount: number
}

export type APIAppStatus = 'dev' | 'in_review' | 'production' | 'changes_req'

export type Actions = 'resetDunning' | 'resetSubscription'

/*
 * Audit Logs models
 */
export interface AuditLog {
  createdAt: string
  eventType: string
  email: string
  ouraUserUid: string | null
  requestSource: string | null
  ouraUserEmail: string | null
  json: AuditLogJson
  userData: AuditLogUser
  organizationUid: string | null
  organizationName: string | null
}

export interface AuditLogUser {
  groups: UserRights[]
}

export interface AuditLogJson {
  ticket?: string
  regexp?: string
  orgUid?: string
  orgName?: string
  userEmail?: string
  searchEnvs?: string[]
  searchFlags?: string[]
  searchPhrase?: string
}

export interface AuditLogOpts {
  page: number
  filters?: string[]
  search?: string
  logs?: AuditLog[]
}

/*
 * Plotly
 */

export interface PlotlyFigure {
  data: Plotly.Data[]
  layout: Partial<Plotly.Layout>
}

export interface ppgPlotDataResponse {
  figures: { graph: string }[]
}

export enum GraphMode {
  WebGL = 'WebGL',
  SVG = 'SVG',
}

export enum PlotTypes {
  Scatter = 'scatter',
  ScatterGL = 'scattergl',
}

export interface BrazeUser {
  externalId: string
  brazeId: string
}

export interface ReturnEligibilityInfo {
  thresholdDays: number | null
  eligibleForReturn: boolean | null
  daysSinceFulfillment: number | null
}

export interface ReturnEligibilityInfoDisplay {
  isEligible: boolean
  daysRemaining: number
  statusIcon: string
  statusColor: string
  statusMessage: string
}

export interface RingDetails {
  macAddress: string
  hardwareType: string
  firmwareVersion: string
  hardwareVersion: string
  bootloaderVersion: string
  serialNumber: string
  bootloaderUpdatedOn: string | null
  firmwareUpdatedOn: string | null
  registrationDate: string | null
  lastSeenDate: string
  design: string | null
  color: string | null
  ringSerialInfo: SerialDetails | null
}

export interface Ring {
  bootloaderUpdatedOn: string | null
  bootloaderVersion: string
  color: string | null
  design: string | null
  firmwareUpdatedOn: string | null
  firmwareVersion: string
  hardwareType: string
  hardwareVersion: string

  /**
   * Same as ringInfoModifiedAt we receive from API
   */
  lastSeenDate: string
  macAddress: string

  /**
   * Will be deprecated in the future
   */
  registrationCount?: number

  /**
   * Will be deprecated in the future
   */
  registrationDate?: string

  serialNumber: string

  // New response fields - start using only these after we no longer use ring data from users endpoint.
  // At that point we can migrate from "to be deprecated" fields to these, and remove unused ones.
  // Check null & optional configuration from UserRingDetailsResponse
  chargerSerialNumber?: string | null
  clientFlavor: string | null
  clientVersion: string | null
  clientUpdatedOn: string | null
  deviceModel: string | null
  deviceOs: string | null
  deviceOsVersion: string | null
  deviceOsUpdatedOn: string | null
  offeredFirmwareVersion?: string | null
  offeredBootloaderVersion?: string | null
  netsuiteSalesOrderId?: string | null
  orderStatus?: string | null
  orderTimestamp?: string | null
  returnReason?: string | null
  returnStatus?: string | null
  ringInfoModifiedAt?: string | null
  ringSerialNumber?: string | null
  warrantyStartDate?: string | null
  warrantyEndDate?: string | null
  zendeskNotes?: string[] | null
  ringSerialInfo: SerialDetails | null
  chargerSerialInfo: SerialDetails | null
  lastSyncedAt: string | null
  fulfillmentTimestamp?: string | null
  returnEligibility: ReturnEligibilityResponse | null
}

interface MemberEnv {
  /**
   * For example "dev", "production"
   */
  environment: string | null

  /**
   * For example "api". This is not used anywhere in the code.
   */
  serviceName: string | null

  /**
   * For example "tornado". This is not used anywhere in the code.
   */
  serviceType: string | null
}

interface MemberEmailPasswordEvent {
  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  createdAt: string

  /**
   * For example "fwpwd_web", "pwreset"
   */
  eventType: string

  /**
   * For example: { "email": "foobar@example.com" }
   */
  json: any | null
}

export interface MemberEmail {
  /**
   * Email address, for example foobar@example.com
   */
  email: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  createdAt: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  deletedAt: string | null
  verified: boolean
  deleted: boolean

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  verifiedAt: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  verificationSent: string | null
  currentEmail: boolean

  /**
   * This field was missing on dev response, was present in production
   *
   * After cloudManagementUserDetails is in use for everyone, this can be removed.
   * Events will be present in Member.events (MXPRODS-2874)
   */
  passwordEvents?: MemberEmailPasswordEvent[]
}

export interface MemberDevice {
  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  id: string | null

  /**
   * For example iPhone15,2
   */
  model: string | null

  /**
   * For example: "ios" or "android"
   */
  os: string | null

  /**
   * For example "16.4.1"
   */
  osVersion: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  osVersionUpdatedOn: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  firstSeen: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  lastSeen: string | null

  /**
   * For example "debug", "release"
   */
  clientFlavor: string | null

  /**
   * Integer, for example 2
   */
  registrationCount: number | null
}

export interface MemberClient {
  /**
   * For example: "ios" or "android"
   */
  platform: string | null

  /**
   * Integer, for example 2
   */
  registrationCount: number | null

  /**
   * For example "debug", "release"
   */
  flavor: string | null

  /**
   * For example "en", "fi"
   */
  language: string | null

  /**
   * For example "4.10.1 (2303151455)"
   */
  version: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  updatedOn: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  firstSeen: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  lastSeen: string | null
}

/**
 * Properties of this interface should be converted to camelCase if we keep using this.
 */
export interface MemberDeleteTicket {
  /**
   * Some examples of possible values: [all, braze, db, s3-data-v1, s3-stream-parts,
   * s3-stream-full, s3-store-zip, s3-data-lake-db, s3-data-lake-jzlog]
   */
  aspects: string[]

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  created_at: string

  /**
   * Email address, for example foobar@example.com
   */
  initiating_admin_email: string | null

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  initiating_admin_uid: string | null

  /**
   * For example "app"
   */
  initiation_type: string | null

  /**
   * Possible values: [initial, complete, requested]
   */
  status: string

  /**
   * The unique identifier for the deletion ticket
   *
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  uid: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  updated_at: string

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  user_uid: string
}

interface MemberFlags {
  jzlogProcessingDisabled: boolean | null
  usingExperimentalApp: boolean | null
  queuedForDeletion: boolean | null
  markedForDeletion: boolean | null
  userRequestedDeletion: boolean | null
}

export interface MemberEvent {
  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  occurredAt: string

  /**
   * For example "fwpwd_web", "pwreset"
   */
  eventType: string

  /**
   * For example: { "email": "foobar@example.com" }
   */
  json: any | null
}

/**
 * This object contains all member related data.
 *
 * Please note that this is only available when newMemberObject feature flag is enabled.
 */
export interface Member {
  /**
   * Member's user ID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  uuid: string

  /**
   * Member's analytics ID, for example 9e8675b9-8ddc-4ec1-95da-ff5246ec2fd0
   */
  analyticsId: string | null
  userCreatedAt: string | null
  /**
   * Email address, for example foobar@example.com
   *
   * Null if user doesn't have (personal data) access to see member's email
   */
  email: string | null
  emailVerified: boolean | null

  /**
   * Integer, for example 46
   *
   * Not used anywhere, should be removed
   */
  percentile: number | null

  /**
   * Integer, for example 2
   */
  ringCount: number

  /**
   * Timestamp, for example "2023-04-27T05:15:19.478100+00:00"
   */
  lastChange: string | null
  env: MemberEnv
  ownerAccess: boolean
  supportAccess: boolean
  sharedAccess: boolean

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  engagementId: string | null
  isVerificationBypassed: boolean

  /**
   * Integer, for example 3
   */
  timeZone: number | null
  rings: Ring[]
  emails: MemberEmail[]
  devices: MemberDevice[]
  clients: MemberClient[]
  deleteTicket: MemberDeleteTicket | null
  deleteTickets: MemberDeleteTicket[]
  flags: MemberFlags

  /**
   * This is only present when cloudManagementUserDetails is enabled
   *
   * Support to display events needs to be added (MXPRODS-2874)
   */
  events: MemberEvent[]

  hipaaStatus: boolean
  countryOfResidence: string | null
}

export interface AuditUser {
  email: string
  rights: string[]
  dataConsent?: string
  userCreateDate: string
  userLastModifiedDate: string
}

export interface SampleFilter {
  uid: string
  name: string
  description: string
  descriptionUrl: string
}

export interface Diagnostics {
  /**
   * title
   * Example: Daytime Stress
   */
  message: string
  title: string
  status: DiagnosticErrorLevel
  /**
   * guruCardUrl
   * Example: 'https://www.example.com'
   */
  guruCardUrl: string | null
  extraInfo?: {
    /**
     * headers
     * Example: [{text: 'Test', value: 'name' }, { text: 'Result', value: 'result' }, { text: 'Message', value: 'message' }]
     */
    headers: any[]
    /**
     * items
     * Example: [{name: 'Feature Flag', message: 'Feature is not activated for the member', result: 'info' }]
     */
    items: any[]
  }
}

export interface Diagnostic {
  diagnosticId: string
  message: string
  title: string
  status: DiagnosticErrorLevel
  guruCardUrl: string | null
  extraInfo: {
    headers: { title: string; key: string }[]
    items: any[]
  }
  show: {
    darwin: boolean
    darwinSidecar: boolean
  }
  isPriority: boolean
  macroId: string
  isHidden: Nullable<boolean>
}

export enum DiagnosticCategory {
  users = 'users',
  rings = 'rings',
  userRings = 'userRings',
  featureFlag = 'featureFlag',
}

export interface AvailableDiagnostic {
  diagnosticId: string
  category: DiagnosticCategory
  versions: number[]
  versionFeatureFlagName: string
}

/**
 * Contains any number of diagnostics. Property name = diagnosticId
 */
export interface DiagnosticsContainer {
  [key: string]: Diagnostic
}

export interface DiagnosticGroupData {
  /**
   * Group title
   *
   * @example 'Priority and pinned'
   */
  title: string

  /**
   * Is group expanded (true) or collapsed (false) by default
   */
  expanded: boolean
  diagnostics: Diagnostic[]
}

export interface Entitlement {
  ringSerialNumber: string
  entitlementGroup: string
  numberOfActivations: number
  maxNumberOfActivations: number
  contractStartDate: string
  contractEndDate: string
  voided: boolean
  type: string
}

export interface GetResultsFilesParams {
  jobId: string
  env: string
}

export interface GetLogFileParams {
  jobId: string
}

export interface RingSearchResponseSingleRing {
  ringSerialNumber: string | null
  chargerSerialNumber: string | null
  orderTimestamp: string | null
  orderStatus: string | null
  netsuiteSalesOrderId: string | null
  returnReason: string | null
  returnStatus: string | null
  warrantyEndDate: string | null
  warrantyStartDate: string | null
  zendeskNotes: string[] | []
}

export interface RingSearchResponse {
  contents: RingSearchResponseSingleRing[]
  pagination: {
    pageSize: number
    next: string | null
    prev: string | null
    total: number
  }
}

export interface ReproductiveHormoneAnswer {
  /**
   * day
   * format: date
   */
  day: string
  fertilityTreatments: boolean | null

  /**
   * hormonalContraception
   * Type of hormonal contraception used or null
   */
  hormonalContraception: string | null
  hormoneReplacementTherapy: boolean | null

  /**
   * other
   * True if user uses other hormone
   */
  other: boolean | null
  preferNotToAnswer: boolean | null
}

/**
 * Vuetify v-select item. Format from: https://v2.vuetifyjs.com/en/api/v-select/#props
 */
export interface VuetifySelectItem {
  text: string | number | any
  value: string | number | any
  disabled?: boolean
  divider?: boolean
  header?: string
}

/*
 * Ring or Charger serial number details
 */

export interface SerialDetails {
  product: string | null
  factory: string | null
  hwMinor: string | null
  hwMajor: string | null
  size: number | null
  manufacturingWeek: number | null
  manufacturingYear: number | null
  generation: string | null
}

export interface AbortControllerContainer {
  requestSource: string
  controller: AbortController
  requestId: string
}

export interface DataWaits {
  [key: string]: boolean
}

export interface DataWait {
  source: string
  wait: boolean
}

export interface GraphOptions {
  categories: Categories
  yaxes: Yaxes
  groups: GraphGroups
}

export interface Categories {
  [key: string]: {
    [key: string]: CategoriesKeyObject
  }
}

export interface CategoriesKeyObject {
  allowed: boolean
  cacheWindow: number
  description: string
  graph_type: string
  name: string
  usage_info: string
  weight: number
  yaxis: string
  show_in_troubleshooter: boolean
  needsRingSerial: boolean
}

export interface GraphGroups {
  [key: string]: GraphGroupItem
}

export interface GraphGroupItem {
  description: string
  graphs: string[]
  title: string
}

interface Yaxes {
  common: {
    autoshift: boolean
    fixedrange: boolean
    gridcolor: string
    linecolor: string
    linewidth: number
    mirror: boolean
    showgrid: boolean
    showline: boolean
    tickmode: string
    ticksuffix: string
  }
  zero_to_2hundred: object | { range: number[] }
  zero_to_hundred: object | { range: number[] }
  zero_to_inf: object | { range: number[] }
  zero_to_twenty_five: object | { range: number[] }
}

export interface AvailableGraphs {
  name: string
  description: string
  usageInfo: string
  allowed: boolean
  graphType: string
  value: string
  color: string
  weight: number
  cacheWindow: number
  needsRingSerial: boolean
}

export interface GraphFilterWeights {
  /**
   * Filter ID and it's weight
   */
  [key: string]: number
}

export interface GraphCacheWindows {
  /**
   * Filter ID and it's cache window
   */
  [key: string]: number
}

export interface BaseDeviceInfo {
  serialNumber: string
  factory: Nullable<string>
  hwMajor: Nullable<string>
  hwMinor: Nullable<string>
  product: Nullable<string>
  size: Nullable<number>
  color: Nullable<string>
  design: Nullable<string>
  manufacturingYear: Nullable<number>
  manufacturingWeek: Nullable<number>
  serialNumberTitle: string
}

export interface DeviceInfoCharger extends BaseDeviceInfo {
  refurbished: false
  macAddress: null
  macAddressTitle: null
}

export interface DeviceInfoRing extends BaseDeviceInfo {
  refurbished: boolean
  macAddress: Nullable<string>
  macAddressTitle: string
}

/**
 * Data we want to show on device info panel about ring and charger for the user in its final form.
 */
export interface DeviceInfo {
  description: string | null
  ring?: DeviceInfoRing
  charger?: DeviceInfoCharger
}

export interface WarrantyReturnInfoReplacementFor {
  /**
   * Return authorization transaction ID
   *
   * @example RA-6983371
   */
  returnAuthorizationTransactionId: Nullable<string>

  serialNumberTitle: 'Ring serial number' | 'Charger serial number' | string

  /**
   * Device serial number
   *
   * @example Y0968457361,09
   */
  serialNumber: string

  /**
   * Device serial number (parsed)
   *
   * The first part of the serial number
   *
   * @example Y0968457361
   */
  serialNumberParsed: string
}

export interface WarrantyReturnInfoReplacedBy {
  /**
   * Return authorization transaction ID
   *
   * @example SO-342414
   */
  orderTransactionId: Nullable<string>

  serialNumberTitle: 'Ring serial number' | 'Charger serial number' | string

  /**
   * Device serial number
   *
   * @example Y0968457361,09
   */
  serialNumber: string

  /**
   * Device serial number (parsed)
   *
   * The first part of the serial number
   *
   * @example Y0968457361
   */
  serialNumberParsed: string
}

export interface WarrantyReturnInfoReturnedItem {
  itemType: 'Ring' | 'Charger' | 'Cable' | string

  /**
   * Return number
   *
   * @example 810209
   */
  returnNumber: number

  /**
   * Email of agent handling the return
   *
   * @example test.user.5861321@ouraring.com
   */
  handlerEmail: Nullable<string>

  factoryResetStatus: Nullable<string>

  factoryResetReason: Nullable<string>

  /**
   * Item marked as returned at
   *
   * @example 2023-07-14T07:00:00+00:00
   */
  returnedAt: string
}

export interface WarrantyReturnInfoReturnAuthorization {
  /**
   * Return authorization transaction ID
   *
   * @example RA-6983371
   */
  transactionId: string

  /**
   * Reason for replacement
   *
   * @example 1 Battery Draining
   */
  reason: Nullable<string>

  /**
   * Replacement status
   *
   * @example Closed
   */
  status: Nullable<string>

  /**
   * Timestamp
   *
   * @example 2023-07-14T07:00:00+00:00
   */
  timestamp: string

  /**
   * Last modified at
   *
   * @example 2023-07-14T07:00:00+00:00
   */
  lastModifiedAt: string

  returnedItems: WarrantyReturnInfoReturnedItem[]
}

export interface WarrantyReturnInfo {
  replacementFor: Nullable<WarrantyReturnInfoReplacementFor>
  replacedBy: Nullable<WarrantyReturnInfoReplacedBy>
  returnAuthorization: Nullable<WarrantyReturnInfoReturnAuthorization>
  warrantyNotes: Nullable<string>
}

export interface WarrantyInfo {
  /**
   * Warranty status
   *
   * @example "In warranty", "Out of warranty"
   */
  warrantyStatus: string

  /**
   * Warranty start date
   *
   * @example 2023-04-18T10:02:44.770794+00:00
   */
  warrantyStartDate: Nullable<string>

  /**
   * Warranty end date
   *
   * @example 2023-04-18T10:02:44.770794+00:00
   */
  warrantyEndDate: Nullable<string>

  /**
   * Extended Service Contract start date
   *
   * @example 2023-04-18T10:02:44.770794+00:00
   */
  escStartDate: Nullable<string>

  /**
   * Extended Service Contract end date. This extends the regular warranty end date.
   *
   * @example 2023-04-18T10:02:44.770794+00:00
   */
  escEndDate: Nullable<string>

  /**
   * SO transaction ID
   *
   * @example SO-58374633
   */
  soTransactionId: Nullable<string>

  /**
   * Fulfillment status title
   *
   * @example 'Order shipped date'
   */
  fulfillmentStatusTitle: string

  /**
   * Fulfillment timestamp
   *
   * @example 2023-04-18T10:02:44.770794+00:00
   */
  fulfillmentTimestamp: Nullable<string>

  /**
   * Retail partner
   */
  retailPartner: Nullable<string>

  /**
   * Retail channel
   *
   * @example 'D2C'
   */
  retailChannel: Nullable<string>
}

export interface WarrantyInfoTitles {
  warrantyStatus: string
  warrantyStartDate: string
  escStartDate: string
  soTransactionId: string
  fulfillmentTimestamp: string
  warrantyEndDate: string
  escEndDate: string
  retailPartner: string
  retailChannel: string
}

export interface ApiErrorResponse {
  message: string
  code: string
  title: string
}

type ApiErrorResponseType = ApiErrorResponse

export function isApiErrorResponse(x: unknown): x is ApiErrorResponseType {
  if (x === undefined || x === null) {
    return false
  }

  const testType = x as ApiErrorResponseType
  return testType.message !== undefined && testType.code !== undefined && testType.title !== undefined
}

export type Nullable<T> = T | null

export interface RequestErrors {
  [key: string]: Nullable<RequestError>
}

export interface FormValidationProblem {
  field?: Nullable<string>
  message: string
  corrected?: Nullable<string>
}

/**
 * Form validation error response.
 *
 * Response format is exactly the same as SUPA API address validation response
 * https://github.com/jouzen/outo-cloud/blob/1dd3f11ff15c531440815ddb52ca961d7bb62926/mock_api/supa_api.json#L8048-L8082
 */
export interface FormValidationErrorResponse {
  success: boolean
  problems: FormValidationProblem[]
}

type FormValidationErrorResponseType = FormValidationErrorResponse

export function isFormValidationError(x: unknown): x is FormValidationErrorResponse {
  if (x === undefined || x === null) {
    return false
  }

  const testType = x as FormValidationErrorResponseType
  return testType.success !== undefined && testType.problems !== undefined
}

/*
 *  HTTP response with status code 401 and response content: {"message":"Unauthorized"}
 */
export interface ApiGWErrorResponse {
  message: string
}

type ApiGWErrorResponseType = ApiGWErrorResponse

export function isApiGWErrorResponse(x: unknown): x is ApiGWErrorResponseType {
  if (x === undefined || x === null) {
    return false
  }

  const testType = x as ApiGWErrorResponseType
  return testType.message !== undefined
}

export interface LatestVersions {
  firmwareVersion: Nullable<string>
  bootloaderVersion: Nullable<string>
  minimumOsVersion: Nullable<string>
  appVersion: Nullable<string>
}

interface SingleNotification {
  message: string
  severity: 'error' | 'info'
}

export interface Notifications {
  [key: string]: SingleNotification
}

export interface DarwinFeatureFlags {
  enabled: string[]
  disabled: string[]
}

export enum DisplayTimezone {
  UTC = 'UTC',
  MEMBER = 'MEMBER',
}

export interface SummaryDataReportItem {
  /**
   * Day in YYYY-MM-DD format
   *
   * @example '2024-09-12'
   */
  day: string

  /**
   * Date
   *
   * @example '12 Sep 2024'
   */
  date: string

  ringSerialNumber: Nullable<string>

  /**
   * Member's timezone at the end of the day. Null until then.
   *
   * @example '+03:00' or '-08:00'
   */
  timezone: Nullable<string>

  /**
   * Bedtime start.
   *
   * 'UTC' suffix is added if Darwin user has chosen UTC instead member's time.
   *
   * @example '23:17', '00:10', '03:15 UTC'
   */
  bedtimeStart: Nullable<string>

  /**
   * Bedtime end.
   *
   * 'UTC' suffix is added if Darwin user has chosen UTC instead member's time.
   *
   * @example '08:10', '06:00', '12:15 UTC'
   */
  bedtimeEnd: Nullable<string>
  bedtimeStartFull: Nullable<string>
  bedtimeEndFull: Nullable<string>
  nightlyIbiQuality: Nullable<number>
  dhrIbiQuality: Nullable<string>
  sleepScore: Nullable<number>
  readinessScore: Nullable<number>
  lowestHR: Nullable<number>
  temperature: Nullable<number>
  dailyMotion: Nullable<number>
  nonWearMinutes: Nullable<number>
  nonWearMinutesDuringBedtime: Nullable<number>
  nonWearPeriods: Nullable<number>
  selfTestSuccess: Nullable<boolean>
  selfTestDetails: string[]
  fieldTestSuccess: Nullable<boolean>
  fieldTestDetails: string[]
  events: string[]
  isExpandable: boolean
}

export interface SleepPeriodsItem {
  index: number

  /**
   * Day in YYYY-MM-DD format
   *
   * @example '2024-09-12'
   */
  day: string

  /**
   * Date formatted
   *
   * @example '12 Sep 2024'
   */
  date: string

  /**
   * Bedtime start, full timestamp with timezone
   *
   * @example "2024-09-04T22:02:44.770794+03:00"
   */
  bedtimeStartFull: Nullable<string>

  /**
   * Bedtime end, full timestamp with timezone
   *
   * @example "2024-09-04T22:02:44.770794+03:00"
   */
  bedtimeEndFull: Nullable<string>

  /**
   * Formats the bedtime start time as DD MMM HH:mm
   * 'UTC' suffix is added if Darwin user has chosen UTC instead member's time.
   *
   * @example "03 Sep 18:41 UTC"
   * @example "03 Sep 15:41"
   */
  bedtimeStart: Nullable<string>

  /**
   * Formats the bedtime end time as DD MMM HH:mm
   * 'UTC' suffix is added if Darwin user has chosen UTC instead member's time.
   *
   * @example "03 Sep 22:05 UTC"
   * @example "03 Sep 19:05"
   */
  bedtimeEnd: Nullable<string>

  /**
   * Duration between bedtime start and end in format `hours minutes`
   *
   * @example "12h 30m".
   */
  duration: string
  type: Nullable<string>
}
